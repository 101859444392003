exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-business-index-tsx": () => import("./../../../src/pages/business/index.tsx" /* webpackChunkName: "component---src-pages-business-index-tsx" */),
  "component---src-pages-career-index-tsx": () => import("./../../../src/pages/career/index.tsx" /* webpackChunkName: "component---src-pages-career-index-tsx" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../../../src/pages/contact-us/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-cookie-policy-index-tsx": () => import("./../../../src/pages/cookie-policy/index.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-index-tsx" */),
  "component---src-pages-dedicated-team-index-tsx": () => import("./../../../src/pages/dedicated-team/index.tsx" /* webpackChunkName: "component---src-pages-dedicated-team-index-tsx" */),
  "component---src-pages-dev-tsx": () => import("./../../../src/pages/dev.tsx" /* webpackChunkName: "component---src-pages-dev-tsx" */),
  "component---src-pages-discovery-phase-index-tsx": () => import("./../../../src/pages/discovery-phase/index.tsx" /* webpackChunkName: "component---src-pages-discovery-phase-index-tsx" */),
  "component---src-pages-faq-index-tsx": () => import("./../../../src/pages/faq/index.tsx" /* webpackChunkName: "component---src-pages-faq-index-tsx" */),
  "component---src-pages-home-index-ts": () => import("./../../../src/pages/home/index.ts" /* webpackChunkName: "component---src-pages-home-index-ts" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mvp-index-tsx": () => import("./../../../src/pages/mvp/index.tsx" /* webpackChunkName: "component---src-pages-mvp-index-tsx" */),
  "component---src-pages-portfolio-index-tsx": () => import("./../../../src/pages/portfolio/index.tsx" /* webpackChunkName: "component---src-pages-portfolio-index-tsx" */),
  "component---src-pages-post-index-tsx": () => import("./../../../src/pages/post/index.tsx" /* webpackChunkName: "component---src-pages-post-index-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-project-index-tsx": () => import("./../../../src/pages/project/index.tsx" /* webpackChunkName: "component---src-pages-project-index-tsx" */),
  "component---src-pages-proof-of-concept-index-tsx": () => import("./../../../src/pages/proof-of-concept/index.tsx" /* webpackChunkName: "component---src-pages-proof-of-concept-index-tsx" */),
  "component---src-pages-startups-index-tsx": () => import("./../../../src/pages/startups/index.tsx" /* webpackChunkName: "component---src-pages-startups-index-tsx" */),
  "component---src-pages-terms-index-tsx": () => import("./../../../src/pages/terms/index.tsx" /* webpackChunkName: "component---src-pages-terms-index-tsx" */)
}

